html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
}
#zappar-button {
	position: absolute;
	bottom: 120px !important;
	width: 200px;
	left: calc(50% - 100px);
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	text-align: center;
	font-family: sans-serif;
	padding: 10px;
	border-radius: 5px;
	position: 'absolute';
}
